import request from '@/utils/request'


// 查询所有厅房
export function findHallInspections(params) {
    return request({
        url: `/hall_inspections`,
        method: 'get',
        params: params
    })
}
