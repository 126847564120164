<template>
  <div>
    <search-hall-inspection @submit="submitSearch" />

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="fetchData"
      :scroll="{ x: 'max-content' }"
    >
      <div slot="compressor_temperature" slot-scope="compressor_temperature, record">
        {{ compressor_temperature }}{{'℃'}}
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchHallInspection from '@/views/hall_inspections/Search'
import PreviewImage from '@/components/PreviewImage'
import {findHallInspections} from "@/api/hall_inspection";

export default {
  name: 'HallInspectionList',
  components: {
    Pagination,
    PreviewImage,
    SearchHallInspection,
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  created() {
    this.fetchData()
  },
  destroyed() {
  },
  computed: {
    columns() {
      return [
        {
          title: '巡检时间',
          dataIndex: 'created_at',
          width: 120,
          fixed: 'left',
        },
        {
          title: '巡检人',
          width: 80,
          dataIndex: 'user_name',
        },
        {
          title: '厅房名称',
          width: 120,
          dataIndex: 'hall_name',
        },
        {
          title: '逝者名称',
          width: 100,
          dataIndex: 'death_name',
        },
        {
          title: '灵堂内外垃圾袋更换',
          width: 120,
          dataIndex: 'bag_replacement'
        },
        {
          title: '蜡烛修改、杯子更换',
          width: 120,
          dataIndex: 'cup_change',
        },
        {
          title: '空调、蓝牙音响调整',
          width: 120,
          dataIndex: 'air_conditioning_adjustment',
        },
        {
          title: '电视背景是否制作与播放',
          width: 120,
          dataIndex: 'background_play'
        },
        {
          title: '棺木擦拭',
          width: 80,
          dataIndex: 'coffin_wiping'
        },
        {
          title: '压缩机温度',
          dataIndex: 'compressor_temperature',
          width: 80,
          scopedSlots: { customRender: 'compressor_temperature' }
        },
        {
          title: '罗马柱、告示牌摆放',
          dataIndex: 'sign_placement',
          width: 120,
        },
        {
          title: '相框架、高台背板擦拭',
          dataIndex: 'background_placement',
          width: 120,
        },
        {
          title: '下水盆清理',
          dataIndex: 'lower_basin_clean',
          width: 80,
        },
        {
          title: '围观花是否新鲜、拼错',
          dataIndex: 'bystander',
          width: 120,
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 200,
          ellipsis: true,
        }
      ]
    }
  },
  methods: {
    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findHallInspections(this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },
  }
}
</script>
